<template>
  <div>
    <b-row>
      <b-col md="12" lg="6">
        <b-card >
          <b><p>เพศ</p></b>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="gender"
              name="gender"
              value="all"
              class="custom-control-primary"
            >
              ทั้งหมด
            </b-form-radio>
            <b-form-radio
              v-model="gender"
              name="gender"
              value="male"
              class="custom-control-primary"
            >
              ชาย
            </b-form-radio>
            <b-form-radio
              v-model="gender"
              name="gender"
              value="female"
              class="custom-control-primary"
            >
              หญิง
            </b-form-radio>
          </div>
          <br />
          <div>
            <b><p>OS</p></b>
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="os"
                value="Windows"
                class="custom-control-primary"
              >
                Windows
              </b-form-checkbox>
              <b-form-checkbox
                v-model="os"
                value="Mac"
                class="custom-control-primary"
              >
                Mac OS X
              </b-form-checkbox>
              <b-form-checkbox
                v-model="os"
                value="Linux"
                class="custom-control-primary"
              >
                Linux
              </b-form-checkbox>
              <b-form-checkbox
                v-model="os"
                value="iOS"
                class="custom-control-primary"
              >
                iOS
              </b-form-checkbox>
              <b-form-checkbox
                v-model="os"
                value="Android"
                class="custom-control-primary"
              >
                Android
              </b-form-checkbox>
            </div>
            <hr />
            <b><p>ช่วงอายุ</p></b>
            <vue-slider
              v-model="age"
              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
            <br />
            <b>อายุ {{ age[0] }}-{{ age[1] }} ปี.</b>
            <hr />
            <br /><br />
            <b>แทก</b>
            <b-card >
              <div>
                <b-form-group>
                  <b-form-tags v-model="value" no-outer-focus>
                    <template v-slot="{ tags, disabled, addTag, removeTag }">
                   
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="primary"
                            @remove="removeTag(tag)"
                          >
                            {{ tag }}
                          </b-form-tag>
                        </li>
                      </ul>

                      <b-dropdown
                        size="sm"
                        variant="outline-secondary"
                        block
                        menu-class="w-100"
                      >
                        <template v-slot:button-content>
                          <b-icon icon="tag-fill" /> Choose tags
                        </template>
                        <b-dropdown-form @submit.stop.prevent="() => {}">
                          <b-form-group
                            label-for="tag-search-input"
                            label="Search tags"
                            label-cols-md="auto"
                            class="mb-0"
                            label-size="sm"
                            :description="searchDesc"
                            :disabled="disabled"
                          >
                            <b-form-input
                              id="tag-search-input"
                              v-model="search"
                              type="search"
                              size="sm"
                              autocomplete="off"
                            />
                          </b-form-group>
                        </b-dropdown-form>
                        <b-dropdown-divider />
                        <b-dropdown-item
                          v-for="option in availableOptions"
                          :key="option"
                          @click="onOptionClick({ option, addTag })"
                        >
                          {{ option }}
                        </b-dropdown-item>
                        <b-dropdown-text v-if="availableOptions.length === 0">
                          There are no tags available to select
                        </b-dropdown-text>
                      </b-dropdown>
                    </template>
                  </b-form-tags>
                </b-form-group>
              </div>
            </b-card>
          </div>
        </b-card>
      </b-col>
      <b-col md="12" lg="6">
        <b-card>
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">Goal Overview</h4>
              <b-card-text class="font-medium-5 mb-0">
                <feather-icon
                  icon="HelpCircleIcon"
                  size="21"
                  class="text-muted cursor-pointer"
                />
              </b-card-text>
            </b-card-header>

            <!-- apex chart -->
            <vue-apex-charts
              type="radialBar"
              height="245"
              :options="goalOverviewRadialBar.chartOptions"
              :series="goalOverviewRadialBar.series"
            />
            <b-row class="text-center mx-0">
              <b-col
                cols="6"
                class="
                  border-top border-right
                  d-flex
                  align-items-between
                  flex-column
                  py-1
                "
              >
                <b-card-text class="text-muted mb-0">
                  จำนวนผู้ใช้งาน
                </b-card-text>
                <h3 class="font-weight-bolder mb-50">10,000</h3>
              </b-col>

              <b-col
                cols="6"
                class="border-top d-flex align-items-between flex-column py-1"
              >
                <b-card-text class="text-muted mb-0">
                  จำนวนที่มีอยู่แล้ว
                </b-card-text>
                <h3 class="font-weight-bolder mb-0">5,999</h3>
              </b-col>
            </b-row>
          </b-card>
          <b-row class="avg-sessions pt-50">
            <b-col cols="6" class="mb-2">
              <b-card-text class="mb-50"> ผู้ใช้ใหม่</b-card-text>
              <b-progress value="2692" max="50000" height="6px" />
            </b-col>
            <b-col cols="6" class="mb-2">
              <b-card-text class="mb-50"> Users: 60% </b-card-text>
              <b-progress value="60" max="100" height="6px" variant="warning" />
            </b-col>
            <b-col cols="6">
              <b-card-text class="mb-50"> Retention: 70% </b-card-text>
              <b-progress
                value="70"
                max="100"
                height="6px"
                variant="danger"
                class="mt-25"
              />
            </b-col>
            <b-col cols="6">
              <b-card-text class="mb-50">
                Duration: {{ avgData.duration }}yr
              </b-card-text>
              <b-progress
                value="90"
                max="100"
                variant="success"
                height="6px"
                class="mt-25"
              />
            </b-col>
          </b-row> </b-card
      ></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  VBModal,
  BForm,
  BCardTitle,
  BCardBody,
  BBadge,
  BFormCheckbox,
  BFormText,
  BFormDatalist,
  BFormRadio,
  BFormSelect,
  BAlert,
  BFormSpinbutton,
  BFormTags,
  BDropdownForm,
  BDropdownDivider,
  BFormTag,
  BDropdownText,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import { $themeColors } from "@themeConfig";
import VueSlider from "vue-slider-component";
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
    VBModal,
    BForm,
    BCardTitle,
    BCardBody,
    BBadge,
    BFormCheckbox,
    BFormText,
    BFormDatalist,
    BFormRadio,
    BFormSelect,
    BAlert,
    BFormSpinbutton,
    BFormTags,
    BDropdownForm,
    BDropdownDivider,
    BFormTag,
    BDropdownText,
    VueSlider,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avgData: {},
      count: 0,
      data: null,
      targetselect: "all",
      options: [],
      os: ["Windows", "Mac", "Linux", "iOS", "Android"],
      search: "",
      value: [],
      age: [20, 50],
      gender: "all",
      goal_overview: {},
      goalOverviewRadialBar: {
        series: [59.99],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#51e5a8"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "77%",
              },
              track: {
                background: "#ebe9f1",
                strokeWidth: "50%",
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: "#5e5873",
                  fontSize: "2.86rem",
                  fontWeight: "600",
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    };
  },
  created() {},
  mounted() {
    // this.loaddata();
    this.get_tag();
  },
    computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const { criteria } = this;

      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.value.indexOf(opt) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }

      // Show all options available
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  methods: {
    get_tag() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("wifimessage_getTag", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.options = response.data;
        });
    },
  
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },
    loaddata() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("TargetAudience", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.data = response.data;
          console.log("TargetAudience :>> ", this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

